<template>
  <div class="about">
    <Head/>
    <h1 class="h1">This is an about page</h1>
    <div class="box" ref="box"></div>
    <img class="a" src="@/assets/logo.png" alt="" ref="img">
    <img src="/img/bg_service.jpg" alt="">
    <Foot/>
  </div>
</template>

<script>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  components: {
    Head,
    Foot
  },

  data () {
    return {

    }
  },

  mounted () {
    console.log(process.env.VUE_APP_BASE_URL)
    gsap.registerPlugin(ScrollTrigger)
    /* console.log(this.$refs) */
    const { box } = this.$refs
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: box,
        start: 'top center',
        end: '+=300px',
        toggleActions: 'play none none reverse',
        scrub: true,
        pin: true,
        markers: true
      }
    })

    tl.fromTo(
      box,
      {
        opacity: 0,
        scale: 0.2
      },
      {
        opacity: 1,
        scale: 1,
        ease: 'Power4.out'
      }

    )
  }
}
</script>

<style scoped>
  .box {
    height: 60px;
    width: 500px;
    background: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
  }
  .about {
    height: 2000px;
    position: relative;
  }
</style>
